import { $ajaxService } from './request'
import { service } from './request'

/**
 * 任务列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/hxe1lx/edit?toc_node_uuid=miE9LYqnXeJ47uy9#P8ltl
 * @param  {} member_userid 成员id
 * @param  {} status 任务状态，execute-待执行，end-已结束，no_start-未开始
 * @param  {} page
 * @param  {} page_size
 */
export const getTaskList = ({ member_userid, status, page, page_size }) =>
  $ajaxService.post($ajaxService.baseUrl + 'task/work_task_list', {
    member_userid,
    status,
    page,
    page_size
  })

// 引导教程页实时数据
export const getGuidePage = ({ send_record_id, page, page_size, task_id }) =>
  $ajaxService.post($ajaxService.baseUrl + 'task/statistical_data', {
    send_record_id,
    page,
    page_size,
    task_id
  })
